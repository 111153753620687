<template>
  <div class="block-width top-margin mx-auto white rounded mt-7 pa-2 pb-8 pa-sm-3 pb-sm-10">
    <div
      class="
        border-info
        d-flex
        justify-center
        align-center
        rounded
        font-weight-medium
        text-uppercase
        text-body-2
        py-3
        mb-6
        mb-sm-9
      "
    >
      <v-icon size="16" color="info" class="mr-2">
        $check
      </v-icon>
      {{ $t('add_funds.payment_successful') }}
    </div>
    <div class="secondary-darken--text text-center mb-4">
      {{ $t('add_funds.payment') }}
    </div>
    <div class="links width-229 d-flex flex-wrap justify-center align-center mx-auto">
      <router-link :to="{ name: routeNames.CREATE_CAMPAIGN }">
        {{ $t('add_funds.create_campaign') }}
      </router-link>
      <router-link :to="{ name: routeNames.CREATE_AD }" class="black--text">
        {{ $t('add_funds.create_ad') }}
      </router-link>
      <router-link :to="{ name: routeNames.CAMPAIGNS }" class="black--text">
        {{ $t('add_funds.campaigns') }}
      </router-link>
    </div>
  </div>
</template>

<script>
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'AddFundsSuccess',
    data() {
      return {
        routeNames
      }
    }
  }
</script>

<style scoped lang="scss">
  .top-margin {
    @media screen and (min-width: 600px) {
        margin-top: 80px !important;
    }
    @media screen and (min-width: 1440px) {
        margin-top: 104px !important;
    }
  }
  .block-width {
    max-width: 328px;
    @media screen and (min-width: 600px) {
      max-width: 569px;
    }
  }
  .width-229 {
    max-width: 229px;
    @media screen and (min-width: 600px) {
      max-width: unset;
    }
  }
  .links {
    gap: 12px 32px;
    line-height: 1;
  }
  .border-info {
    border: 1px solid var(--v-info-base);
  }
</style>
